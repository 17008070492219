import * as React from 'react'
import Layout from '../Layout'
import { graphql } from "gatsby"

function PageTemplate({data}) {
    const {markdownRemark}=data
    return (
        <Layout
            title={markdownRemark.frontmatter.title}
            htmlClassName={markdownRemark.frontmatter.pageClassName}
            type={markdownRemark.frontmatter.renderType}
        >
            <div id="mdContent" className={`md-content-wrap`}>
                <h1>{markdownRemark.frontmatter.title}</h1>
                <div dangerouslySetInnerHTML={{__html: markdownRemark.html}}/>
            </div>
        </Layout>

    )
}
export const pageQuery = graphql`
query BlogPostQuery($id: String, $language: String) {
  markdownRemark(id: {eq: $id}) {
    id
    frontmatter {
      title
      pageClassName
      renderType
      date
    }
    html
  }
  locales: allLocale(
    filter: {ns: {in: ["common", "index"]}, language: {eq: $language}}
  ) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`
export default PageTemplate
